import React, { useState } from "react";
import BasicModal from "../../ui/dialogs/BasicModal";
import Button from "@material-ui/core/Button";
import CustomSelect from "../../ui/selects/CustomSelect";
import TextField from "@material-ui/core/TextField";

const ReviewerPicker = ({ users = [], addReviewer, open = false, onClose }) => {
  const [reviewer, setReviewer] = useState({ user: undefined, message: "" });
  const isReady = reviewer.user !== undefined;

  const onAddReviewer = (reviewer, isReady, addReviewer, onClose) => {
    if (isReady) {
      addReviewer(reviewer);
      onClose();
    }
  };

  const actionsRender = (
    <div>
      <Button onClick={onClose} color={"default"}>
        Volver
      </Button>
      <Button
        onClick={() => onAddReviewer(reviewer, isReady, addReviewer, onClose)}
        disabled={!isReady}
        color={"primary"}
      >
        Agregar
      </Button>
    </div>
  );
  return (
    <BasicModal
      title={"Agregar revisor"}
      open={open}
      onClose={onClose}
      actions={actionsRender}
    >
      <CustomSelect
        options={users}
        label={"Seleccionar revisor"}
        value={reviewer.user}
        handleChange={event =>
          setReviewer({ ...reviewer, user: event.target.value })
        }
      />
      <TextField
        style={{ margin: 8 }}
        fullWidth
        multiline
        label="Ingrese comentario para el revisor"
        onChange={event =>
          setReviewer({ ...reviewer, message: event.target.value })
        }
      />
    </BasicModal>
  );
};

export default ReviewerPicker;
