import React from "react";
import MaterialTable from "material-table";

export default function UserFullTable({
  users = [],
  addUser,
  updateUser,
  deleteUser
}) {
  const [state, setState] = React.useState({
    columns: [
      { title: "Nombre", field: "name" },
      { title: "Apellido", field: "surname" },
      { title: "Email", field: "email" }
    ]
  });

  return (
    <MaterialTable
      title="Administración de usuarios"
      data={users}
      columns={state.columns}
      editable={{
        onRowAdd: addUser,
        onRowDelete: deleteUser,
        onRowUpdate: updateUser
      }}
    />
  );
}
