import React from "react";
import {Redirect, Route} from "react-router-dom";
import { isAuth } from "./guards";

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            isAuth() === false ? <Component {...props} /> : <Redirect to="/"/>
        }
    />
);

export default PrivateRoute;
