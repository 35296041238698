import React from "react";
import All from "./All";
import { connect } from "react-redux";
import { getOperationsByStatus } from "../../stores/actions/operationActions";

class AllListOperation extends React.Component {
  componentDidMount() {
    this.props.getOperationsByStatus(0);
  }

  render() {
    const { operations } = this.props;
    return (
      <div>
        <All operations={operations} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operations: state.operation.operations
  };
};

export default connect(
  mapStateToProps,
  { getOperationsByStatus }
)(AllListOperation);
