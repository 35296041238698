import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";

const logout = () => {
  localStorage.clear();
  window.location.replace("/signin");
};

export default function Profile() {
  function handleClick() {
    logout();
  }

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
      >
        {/*<i className="material-icons">exit_to_app</i>*/}
        <Avatar>H</Avatar>
      </IconButton>
    </div>
  );
}
