import React from "react";
import { isAuth, hasAdminRole } from "./guards";

import { Redirect, Route } from "react-router-dom";
import { useAlert } from "react-alert";

const AdminRoute = ({ component: Component, ...rest }) => {
  const messenger = useAlert();
  return (
    <Route
      {...rest}
      render={props => {
        if (!isAuth()) {
          return <Redirect to="/" />;
        }

        if (!hasAdminRole()) {
          messenger.error("No tienes suficientes permisos.");
          return <Redirect to="/" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default AdminRoute;
