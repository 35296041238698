import React from "react";
import List from "@material-ui/core/List/index";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ReviewerItem from "./ReviewerItem";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

const SelectedReviewers = ({
  selectedReviewers = [],
  editReviewer,
  removeReviewer
}) => {
  const classes = useStyles();

  return (
    <div>
      <List component="nav" className={classes.root} aria-label="contacts">
        {selectedReviewers.length === 0
          ? "No ha seleccionado revisores"
          : selectedReviewers.map(selectedReviewer => {
              return (
                <ReviewerItem
                  key={selectedReviewer.user.id}
                  reviewer={selectedReviewer}
                  removeReviewer={removeReviewer}
                  editReviewer={editReviewer}
                />
              );
            })}
      </List>
    </div>
  );
};

export default SelectedReviewers;
