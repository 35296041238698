import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  button: {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#7BE256",
    "&:hover": {
      background: "#8DE76C"
    }
  }
}));

export default function ContainedButtons() {
  const classes = useStyles();

  return (
    <div>
      <Button
        variant="outlined"
        className={classes.button}
        href="/create-operation"
      >
        CREAR SOLICITUD
      </Button>
    </div>
  );
}
