import React, { useState } from "react";
import ListItemText from "@material-ui/core/ListItemText/index";
import ListItem from "@material-ui/core/ListItem/index";
import Button from "@material-ui/core/Button/index";
import Grid from "@material-ui/core/Grid/index";
import TextField from "@material-ui/core/TextField/index";
import BasicModal from "../../ui/dialogs/BasicModal";

const ReviewerItem = ({ reviewer, editReviewer, removeReviewer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedReviewer, setSelectedReviewer] = useState(reviewer);

  const renderActions = (
    <div>
      <Button
        onClick={() => {
          removeReviewer(selectedReviewer);
          setIsOpen(false);
        }}
        color="secondary"
        autoFocus
      >
        Quitar
      </Button>
      <Button
        onClick={() => {
          editReviewer(selectedReviewer);
          setIsOpen(false);
        }}
        color="primary"
        autoFocus
      >
        Editar
      </Button>
    </div>
  );

  return (
    <div>
      <BasicModal
        open={isOpen}
        title={`${reviewer.user.name} ${reviewer.user.surname}`}
        onClose={() => setIsOpen(false)}
        actions={renderActions}
      >
        <Grid container spacing={3}>
          <Grid item sm={12} md={12}>
            <TextField
              style={{ margin: 8 }}
              fullWidth
              multiline
              label="Comentarios para el revisor:"
              defaultValue={reviewer.message}
              onChange={event =>
                setSelectedReviewer({
                  ...selectedReviewer,
                  message: event.target.value
                })
              }
            />
          </Grid>
        </Grid>
      </BasicModal>
      <ListItem button onClick={() => setIsOpen(true)}>
        <ListItemText
          primary={`${reviewer.user.name} ${reviewer.user.surname}`}
        />
      </ListItem>
    </div>
  );
};

export default ReviewerItem;
