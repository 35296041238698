import {
  COMPLETE_CONTACT_DATA,
  COMPLETE_CUSTOMER_FORM_STEP,
  COMPLETE_OPERATION_FORM,
  COMPLETE_OPERATION_PERIOD_STEP,
  COMPLETE_OPERATION_SETUP,
  COMPLETE_REQUIRED_DOCUMENTS,
  HIDE_LOADER,
  MOUNT_OPERATION_FORM,
  SHOW_LOADER
} from "./types";
import getCompanySettings from "../../requests/operations/getCompanySettings";
import getCompanyUser from "../../requests/operations/getCompanyUser";
import createOperationRequest from "../../requests/operations/createOperationRequest";
import { handle } from "../../components/errors/errorHandler";


export const mountOperationForm = () => async dispatch => {
  dispatch({ type: SHOW_LOADER });
  try {
    const operationSettingsResponse = await getCompanySettings();
    const getCompanyUsersRequest = await getCompanyUser();
    const formData = {
      registryTypes: operationSettingsResponse.data.data.registryTypes,
      operationTypes: operationSettingsResponse.data.data.operationTypes,
      companyUsers: getCompanyUsersRequest.data.data.users,
      departments: operationSettingsResponse.data.data.departments,
      agreementRoles: operationSettingsResponse.data.data.agreementRoles
    };
    dispatch({
      type: MOUNT_OPERATION_FORM,
      payload: formData
    });
  } catch (error) {
    handle(error);
  } finally {
    dispatch({ type: HIDE_LOADER });
  }
};

export const completeRequestFileStep = requestFileValues => async dispatch => {
  dispatch({
    type: COMPLETE_OPERATION_SETUP,
    payload: requestFileValues
  });
};

export const completeOperationPeriodStep = periodData => dispatch => {
  dispatch({
    type: COMPLETE_OPERATION_PERIOD_STEP,
    payload: periodData
  });
};

export const completeRequiredDocuments = selectedDocuments => dispatch => {
  dispatch({
    type: COMPLETE_REQUIRED_DOCUMENTS,
    payload: selectedDocuments
  });
};

export const completeContactData = contact => dispatch => {
  dispatch({
    type: COMPLETE_CONTACT_DATA,
    payload: contact
  });
};

export const completeCustomerFormStep = customerFileValues => async dispatch => {
  dispatch({
    type: COMPLETE_CUSTOMER_FORM_STEP,
    payload: customerFileValues
  });
};

const mapToBody = formValues => {
  return {
    setup: {
      /*title: formValues.setup.title,*/
      agreementRole: formValues.setup.agreementRole.id,
      registryType: formValues.setup.registryType.id,
      operationType: formValues.setup.operationType.id,
      responsibleDepartment: formValues.setup.responsibleDepartment.id,
      reviewers: formValues.setup.reviewers.map(reviewer => {
        return { user: { id: reviewer.user.id }, message: reviewer.message };
      })
    },
    period: formValues.period,
    requiredDocuments: formValues.requiredDocuments.map(
      requiredDocument => requiredDocument.label
    ),
    contact: formValues.contact
  };
};

export const completeOperationForm = () => async (dispatch, getState) => {
  const body = mapToBody(getState().operationForm.formValues);
  try {
    await createOperationRequest(body);
    dispatch({ type: COMPLETE_OPERATION_FORM, payload: body });
    alert("Se ha creado la operacion correctamente.");
    window.location.replace("/")
  } catch (error) {
    handle(error);
  }
};
