import moment from "moment";

export const getNow = () => {
  console.log(moment());
  return moment().toDate();
};

export const formatDate = date => {
  return moment(date).format("DD-MM-YYYY");
};
