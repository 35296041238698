import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ObservationsList from "../../../components/operations/ObservationsList";

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

export default function FieldRevisionManager({
  observations = [],
  addObservation
}) {
  const classes = useStyles();

  const [newObservation, setNewObservation] = React.useState("");

  return (
    <div className={classes.div}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Observaciones</Typography>
        </ExpansionPanelSummary>

        <div align="center">
          <div className={classes.div}>
            <TextField
              id="standard-textarea"
              label="Añadir observación"
              multiline
              className={classes.textField}
              margin="normal"
              onChange={event => setNewObservation(event.target.value)}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => addObservation(newObservation)}
          >
            Enviar
          </Button>
        </div>

        <ExpansionPanelDetails>
          <ObservationsList observations={observations} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
