import React, { useState } from "react";
import * as moment from "moment";
import { useAlert } from "react-alert";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Date from "./Date";
import Button from "@material-ui/core/Button";

const OperationPeriod = ({ period, onComplete, backStep }) => {
  const messenger = useAlert();
  const [selectedDates, setSelectedDates] = useState(period);

  const onSelectDate = date => {
    setSelectedDates({
      ...selectedDates,
      startDate: date
    });
  };

  const onSelectExpirationDate = date => {
    if (moment(date).isBefore(moment(selectedDates.startDate))) {
      messenger.error("La fecha de termino no puede ser menor a la de inicio.");
      return;
    }
    setSelectedDates({
      ...selectedDates,
      endDate: date
    });
  };

  const onSelectExpirationDateAlert = date => {
    setSelectedDates({
      ...selectedDates,
      alertDate: date
    });
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Duración del contrato
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <Date
            label="Desde"
            date={selectedDates.startDate}
            handleChange={onSelectDate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Date
            label="Hasta"
            date={selectedDates.endDate}
            handleChange={onSelectExpirationDate}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Date
            label="Fecha de Alerta"
            date={selectedDates.alertDate}
            handleChange={onSelectExpirationDateAlert}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={6} sm={6} md={2}>
          <Button onClick={backStep} variant={"contained"}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Button
            onClick={() => onComplete(selectedDates)}
            variant="contained"
            color="primary"
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default OperationPeriod;
