import React from "react";
import DownloadButton from "../../../components/ui/buttons/DownloadButton";
import downloadRequest from "../../../requests/operations/downloadDocument";
import Grid from "@material-ui/core/Grid";
import ApproveButton from "../../../components/ui/buttons/ApproveButton";
import RejectButton from "../../../components/ui/buttons/RejectButton";
import { hasAdminRole } from "../../../router/guards";
import { canApproveOperation } from "../../../services/validatePermissions";

export default function OperationActions({
  operation,
  myCompany,
  changeOperationStatus
}) {
  const handleDownload = async operationId => {
    try {
      const response = await downloadRequest(operationId);
      console.log("AQUI", response);
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      alert(error);
    }
  };

  const stateOptions = [
    { id: 1, name: "Pendiente" },
    { id: 2, name: "Aprobada" },
    { id: 3, name: "Rechazada" }
  ];

  const APPROVE_OPERATION_FLAG = 2;
  const REJECT_OPERATION_FLAG = 3;

  const onApproveOperation = () => {
    changeOperationStatus({ operation, newStatus: APPROVE_OPERATION_FLAG });
  };

  const onRejectOperation = () => {
    changeOperationStatus({ operation, newStatus: REJECT_OPERATION_FLAG });
  };

  const isApproved = operation => operation.status.id === 2;


  const canApprove = operation => {
    return hasAdminRole() || canApproveOperation(operation);
  };

  return (
    <div>
      <Grid container>
        <Grid>
          <DownloadButton
            disabled={!isApproved(operation)}
            title="Generar documento"
            onClick={async () => await handleDownload(operation.id)}
          />
        </Grid>
        {canApprove(operation) && (
          <div>
            <Grid>
              <ApproveButton
                operation={operation}
                title="Aprobar"
                onClick={onApproveOperation}
              />
            </Grid>
            <Grid>
              <RejectButton
                operation={operation}
                title="Rechazar"
                onClick={onRejectOperation}
              />
            </Grid>
          </div>
        )}
      </Grid>
    </div>
  );
}
