import { HIDE_LOADER, SHOW_LOADER } from "../actions/types";

const initialState = {
  active: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADER:
      return { ...state, active: true };
    case HIDE_LOADER:
      return { ...state, active: false };
    default:
      return state;
  }
}
