import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import mainStore from "./stores/mainStore";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const bugsnagClient = bugsnag("68b599ef82861ea60d7325a05ea995a2");
bugsnagClient.use(bugsnagReact, React);

const alertOptions = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE_RIGHT,
  timeout: 10000,
  offset: "150px",
  type: "error",
  // you can also just use 'scale'
  transition: transitions.FADE
};

const ErrorBoundary = bugsnagClient.getPlugin("react");

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={mainStore}>
      <AlertProvider template={AlertTemplate} {...alertOptions}>
        <App />
      </AlertProvider>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
