import "date-fns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import { getNow } from "../../../services/dateService";

export default function MaterialUIPickers({
  date = getNow(),
  handleChange,
  label = ""
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={label}
          value={date}
          onChange={handleChange}
          KeyboardButtonProps={{
            "aria-label": "change date"
          }}
          minDate={date}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
