import * as jwtDecode from "jwt-decode";

export const hasAdminRole = () => {
  const token = localStorage.getItem("token");


  if (!token) {
    return false;
  }
  const payload = jwtDecode(token);
  console.log(payload)
  return payload.user.role === 1;
};
export const isAuth = () => {
  const token = localStorage.getItem("token");

  return !!token;
};
