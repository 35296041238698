import React from "react";
import OperationsFullTable from "../../components/operations/OperationsFullTable";
import { connect } from "react-redux";
import { getOperationsByStatus } from "../../stores/actions/operationActions";

class Pending extends React.Component {

  componentDidMount() {
    this.props.getOperationsByStatus(1);
  }

  render() {
    const { operations } = this.props;
    return (
      <div>
        <OperationsFullTable
          title="Operaciones pendientes"
          operations={operations}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    operations: state.operation.operations
  };
};

export default connect(
  mapStateToProps,
  { getOperationsByStatus }
)(Pending);
