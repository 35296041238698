import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  pos: {
    marginBottom: 12
  },
  root: {
    flexGrow: 1,
    background: "#F5F5F5"
  },
  paperPendding: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "white",
    background: "#A1A9D3"
  },
  paperPenddingFirm: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "white",
    background: "#A1A9D3"
  },
  paperContratcExpiration: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "white",
    background: "#A1A9D3"
  },
  paperMyRequestCreated: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "white",
    background: "#A1A9D3"
  },
  paperInExecution: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "white",
    background: "#A1A9D3"
  },
  paperAssigned: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: "white",
    background: "#A1A9D3"
  },
  link: {
    textDecoration: "none"
  }
}));

export default function OperationsResume({ counts }) {
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Link to={"/pending-operations"} className={classes.link}>
            <Paper className={classes.paperPendding}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Pendientes</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{counts.pending ? counts.pending : 0}</p>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid item xs={12} sm={4}>
          {/* Operaciones Pendientes de firma */}
          <Link to={"/approved-operations"} className={classes.link}>
            <Paper className={classes.paperPenddingFirm}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Pendientes de firma</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{counts.approved ? counts.approved : 0}</p>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        {/*        <Grid item xs={12} sm={4}>
          <Link to={"/approved-operations"} className={classes.link}>
            <Paper className={classes.paperApproved}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Aprobados</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{counts.approved ? counts.approved : 0}</p>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>*/}

        <Grid item xs={12} sm={4}>
          <Link to={"/contracts-to-expire"} className={classes.link}>
            <Paper className={classes.paperContratcExpiration}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Contratos por expirar</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  {/*<p>{counts.rejected ? counts.rejected : 0}</p>*/}
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        {/*
        <Grid item xs={12} sm={4}>
          <Link to={"/rejected-operations"} className={classes.link}>
            <Paper className={classes.paperRejected}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Rechazados</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  <p>{counts.rejected ? counts.rejected : 0}</p>
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>
*/}

        <Grid item xs={12} sm={4}>
          {/* Todas las creadas */}
          <Link to={"/all-operations"} className={classes.link}>
            <Paper className={classes.paperMyRequestCreated}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Mis solicitudes creadas</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  {/*<p>{counts.created ? counts.created : 0}</p>*/}
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid item xs={12} sm={4}>
          {/* Dejar afuera las rechazadas */}
          <Link to={"/"} className={classes.link}>
            <Paper className={classes.paperInExecution}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>En ejecución</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  {/*<p>{counts.approved ? counts.approved : 0}</p>*/}
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>

        <Grid item xs={12} sm={4}>
          {/* Documentos asignados a mi. */}
          <Link to={"/assigned-operations"} className={classes.link}>
            <Paper className={classes.paperAssigned}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <p>Asignadas</p>
                </Grid>
                <Grid item xs={6} md={6}>
                  {/*<p>{counts.approved ? counts.approved : 0}</p>*/}
                </Grid>
              </Grid>
            </Paper>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
