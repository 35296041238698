import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";

const Observation = ({ observation }) => {
  return (
    <ListItem alignItems="flex-start">
      <ListItemText
        secondary={
          <React.Fragment>
            <Typography component="span" variant="body2" color="textPrimary" />
            {observation.message}
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default Observation;
