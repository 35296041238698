import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./views/dashboard/Dashboard";
import SignIn from "./views/signin/SignIn";
import PrivateRoute from "./router/PrivateRoute";
import OnlyGuest from "./router/OnlyGuest";
import Pending from "./views/operations/Pending";
import Approved from "./views/operations/Approved";
import ContractsToExpire from "./views/operations/ContractsToExpire";
import Rejected from "./views/operations/Rejected";
import DefaultLayout from "./layouts/default/DefaultLayout";
import AdminLayout from "./layouts/admin/AdminLayout";
import CreateOperation from "./views/operations/createOperation/CreateOperation";
import listUser from "./views/admin/listUser";
import ViewOperation from "./views/operations/viewOperation/ViewOperation";
import CustomerSignIn from "./views/customer/CustomerSignIn";
import ViewDocument from "./views/customer/ViewDocument";
import CustomerLayout from "./layouts/customer/CustomerLayout";
import AllListOperation from "./views/operations/AllListOperation";
import AdminRoute from "./router/AdminRoute";
import Assigned from "./views/operations/Assigned";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/customer">
          <CustomerLayout>
            <Route exact path="/customer" component={CustomerSignIn} />
            <Route path="/customer/view-document" component={ViewDocument} />
          </CustomerLayout>
        </Route>

        <OnlyGuest path="/signin" component={SignIn} />

        <AdminLayout path="/admin">
          <AdminRoute exact path="/admin/users" component={listUser} />
        </AdminLayout>

        <DefaultLayout>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/all-operations" component={AllListOperation} />
          <PrivateRoute path="/assigned-operations" component={Assigned} />
          <PrivateRoute path="/pending-operations" component={Pending} />
          <PrivateRoute path="/approved-operations" component={Approved} />
          <PrivateRoute path="/rejected-operations" component={Rejected} />
          <PrivateRoute path="/contracts-to-expire" component={ContractsToExpire} />
          <PrivateRoute path="/create-operation" component={CreateOperation} />
          <PrivateRoute
            path="/operations/:operationId"
            component={ViewOperation}
          />
          <PrivateRoute
              path="/o/:operationId"
              component={ViewOperation}
          />
        </DefaultLayout>
      </Switch>
    </Router>
  );
}

export default App;
