import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./styles";
import StepByStep from "../../../components/StepByStep";
import OperationSetup from "./OperationSetup";
import {
  completeContactData,
  completeCustomerFormStep,
  completeOperationForm,
  completeOperationPeriodStep,
  completeRequestFileStep,
  completeRequiredDocuments,
  mountOperationForm
} from "../../../stores/actions/operationFormActions";
import OperationPeriod from "./OperationPeriod";
import RequiredDocuments from "./RequiredDocuments";
import ContactData from "./ContactData";
import FileResume from "./FileResume";

class CreateOperation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0
    };
  }

  componentDidMount() {
    this.props.mountOperationForm();
  }

  classes = styles;

  MAX_STEP_FLAG = 4;

  nextStep = () => {
    if (this.state.activeStep !== this.MAX_STEP_FLAG) {
      this.setState({
        ...this.state,
        activeStep: this.state.activeStep + 1
      });
    }
  };

  backStep = () => {
    if (this.state.activeStep !== 0) {
      this.setState({
        ...this.state,
        activeStep: this.state.activeStep - 1
      });
    }
  };

  completeOperationSetup = operationSetupData => {
    this.props.completeRequestFileStep(operationSetupData);
    this.nextStep();
  };

  completeOperationPeriod = periodsData => {
    this.props.completeOperationPeriodStep(periodsData);
    this.nextStep();
  };

  completeRequiredDocuments = selectedDocuments => {
    this.props.completeRequiredDocuments(selectedDocuments);
    this.nextStep();
  };

  completeContactData = contact => {
    this.props.completeContactData(contact);
    this.nextStep();
  };

  render() {
    const { formData, formValues, completeOperationForm } = this.props;

    const renderFirstStep = (
      <OperationSetup
        setup={formValues.setup}
        agreementRoles={formData.agreementRoles}
        users={formData.companyUsers}
        operationTypes={formData.operationTypes}
        departments={formData.departments}
        registryTypes={formData.registryTypes}
        onComplete={this.completeOperationSetup}
      />
    );

    const renderSecondStep = (
      <OperationPeriod
        period={formValues.period}
        backStep={this.backStep}
        onComplete={this.completeOperationPeriod}
      />
    );

    const renderThirdStep = (
      <RequiredDocuments
        requiredDocumentsOption={formData.requiredDocumentsOptions}
        backStep={this.backStep}
        onComplete={this.completeRequiredDocuments}
      />
    );

    const renderFourStep = (
      <ContactData
        contact={formValues.contact}
        backStep={this.backStep}
        onComplete={this.completeContactData}
      />
    );

    const renderFinalStep = (
      <FileResume
        formValues={formValues}
        backStep={this.backStep}
        completeOperationForm={completeOperationForm}
      />
    );

    return (
      <div className={this.classes.root}>
        <StepByStep
          steps={[
            {
              title: "Configuración de la operación",
              content: renderFirstStep
            },
            {
              title: "Duración de la operación",
              content: renderSecondStep
            },
            {
              title: "Documentos requiridos",
              content: renderThirdStep
            },
            {
              title: "Contacto",
              content: renderFourStep
            },
            {
              title: "Resumen",
              content: renderFinalStep
            }
          ]}
          activeStep={this.state.activeStep}
          successMessage="Finalizado"
        />
        {console.log("estado", this.state)}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    formData: state.operationForm.formData,
    formValues: state.operationForm.formValues
  };
};

export default connect(
  mapStateToProps,
  {
    mountOperationForm,
    completeRequestFileStep,
    completeOperationPeriodStep,
    completeRequiredDocuments,
    completeCustomerFormStep,
    completeOperationForm,
    completeContactData
  }
)(CreateOperation);
