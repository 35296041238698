import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const EventCalendar = ({ events, onSelectEvent }) => {
  return (
    <div style={{ paddingTop: 25 }}>
      <Calendar
        popup={true}
        localizer={localizer}
        events={events}
        views={["month"]}
        onSelectEvent={onSelectEvent}
        style={{ height: 240, width: "97%" }}
        eventPropGetter={event => {
          let newStyle = {
            border: "none",
            boxSizing: "border-box",
            boxShadow: "none",
            borderRadius: "50%",
            backgroundColor: "red",
            cursor: "pointer",
            width: 1,
            height: 1,
            padding: 6
          };

          return {
            className: "",
            style: newStyle
          };
        }}
      />
    </div>
  );
};

export default EventCalendar;
