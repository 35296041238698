import updateOperationRequest from "../../requests/operations/updateOperationRequest";
import getOperationRequest from "../../requests/operations/getOperationRequest";
import uuid from "uuid";

import { handle } from "../../components/errors/errorHandler";

import {
  CHANGE_DOCUMENT_STATUS,
  CHANGE_FIELD_STATUS,
  CHANGE_OPERATION_STATUS,
  GET_DOCUMENT,
  GET_OPERATION,
  GET_OPERATIONS_BY_STATUS,
  UPDATE_OPERATION,
  UPDATED_DOCUMENT,
  UPLOAD_DOCUMENT
} from "./types";
import uploadDocumentFileRequest from "../../requests/operations/uploadDocumentFileRequest";
import getOperationByStatusRequest from "../../requests/operations/getOperationsByStatusRequest";
import {
  getDocumentRequest,
  updateDocumentRequest
} from "../../requests/documentRequests";
import { getUserId } from "../../config/handleToken";

export const getOperation = operationId => async dispatch => {
  try {
    const response = await getOperationRequest(operationId);
    dispatch({
      type: GET_OPERATION,
      payload: response.data.data
    });
  } catch (error) {
    handle(error);
  }
};

export const changeOperationStatus = ({
  operation,
  newStatus
}) => async dispatch => {
  try {
    console.log("nuevo status", newStatus);
    const operationToUpdate = {
      ...operation,
      status: { id: newStatus }
    };
    const response = await updateOperationRequest(operationToUpdate);
    dispatch({
      type: CHANGE_OPERATION_STATUS,
      payload: response.data.data
    });
    if (newStatus === 2) {
      alert("La operación fue aprobada.");
    }
    if (newStatus === 3) {
      alert("La operación fue rechazada.");
    }
  } catch (error) {
    handle(error);
  }
};

export const changeFieldData = ({ document, field }) => async dispatch => {
  try {
    console.log("documento", document);
    console.log("field", field);

    const updatedDocument = {
      ...document,
      fields: document.fields.map(aField => {
        if (aField.label === field.label) {
          return field;
        }
        return aField;
      })
    };
    const response = await updateDocumentRequest(updatedDocument);
    console.log("respuesta", response);
    dispatch({
      type: UPDATED_DOCUMENT,
      payload: response.data.data
    });
    alert("Actualizado correctamente.");
  } catch (error) {
    handle(error);
  }
};

export const changeFieldStatus = ({
  document,
  fieldToUpdate,
  newStatus
}) => async dispatch => {
  try {
    console.log("field", fieldToUpdate);
    console.log("new status", newStatus);
    const updatedDocument = {
      ...document,
      fields: document.fields.map(field => {
        if (field.label === fieldToUpdate.label) {
          return {
            ...field,
            status: newStatus.id
          };
        }
        return field;
      })
    };
    const response = await updateDocumentRequest(updatedDocument);
    dispatch({
      type: CHANGE_FIELD_STATUS,
      payload: response.data.data
    });
    alert("Estado cambiado correctamente.");
  } catch (error) {
    handle(error);
  }
};

export const uploadDocument = ({
  operation,
  requiredDocument,
  file
}) => async dispatch => {
  try {
    const body = {
      operationId: operation.id,
      requiredDocumentLabel: requiredDocument.field.label,
      file
    };
    const response = await uploadDocumentFileRequest(body);
    dispatch({ type: UPLOAD_DOCUMENT, payload: response.data.data });
    alert("El documento se ha subido correctamente.");
  } catch (error) {
    handle(error);
  }
};

export const changeDocumentStatus = ({
  operation,
  documentToUpdate,
  newStatus
}) => async dispatch => {
  const operationToUpdate = {
    ...operation,
    requiredDocuments: operation.requiredDocuments.map(requiredDocument => {
      if (requiredDocument.field.label === documentToUpdate.field.label) {
        return {
          ...requiredDocument,
          status: newStatus.id
        };
      }
      return requiredDocument;
    })
  };
  try {
    const response = await updateOperationRequest(operationToUpdate);
    dispatch({
      type: CHANGE_DOCUMENT_STATUS,
      payload: response.data.data
    });
    alert("Estado cambiado correctamente.");
  } catch (error) {
    handle(error);
  }
};

export const getOperationsByStatus = status => async dispatch => {
  try {
    const operations = await getOperationByStatusRequest(status);

    dispatch({
      type: GET_OPERATIONS_BY_STATUS,
      payload: operations
    });
  } catch (error) {
    handle(error);
  }
};

export const getDocument = operationId => async dispatch => {
  try {
    const { data } = await getDocumentRequest(operationId);
    dispatch({ type: GET_DOCUMENT, payload: data.data });
  } catch (error) {
    handle(error);
  }
};

export const addObservationToField = ({
  document,
  field,
  newObservation
}) => async dispatch => {
  const observation = {
    id: uuid.v4(),
    createdAt: Date.now(),
    owner: getUserId(),
    message: newObservation
  };

  const documentToUpdate = {
    ...document,
    fields: document.fields.map(aField => {
      if (aField.label === field.label) {
        return {
          ...aField,
          observations: [...aField.observations, observation]
        };
      }
      return aField;
    })
  };
  console.log("documento a actualizar", documentToUpdate);

  try {
    const response = await updateDocumentRequest(documentToUpdate);
    dispatch({ type: UPDATED_DOCUMENT, payload: response.data.data });
  } catch (error) {
    handle(error);
  }
};

export const addObservationToFile = ({
  operation,
  requiredDocument,
  newObservation
}) => async dispatch => {
  const observation = {
    id: uuid.v4(),
    createdAt: Date.now(),
    owner: getUserId(),
    message: newObservation
  };

  const operationToUpdate = {
    ...operation,
    requiredDocuments: operation.requiredDocuments.map(aRequiredDocument => {
      if (aRequiredDocument.field.label === requiredDocument.field.label) {
        return {
          ...aRequiredDocument,
          observations: [...aRequiredDocument.observations, observation]
        };
      }
      return aRequiredDocument;
    })
  };

  try {
    const response = await updateOperationRequest(operationToUpdate);
    dispatch({
      type: UPDATE_OPERATION,
      payload: response.data.data
    });
  } catch (error) {
    handle(error);
  }
};
