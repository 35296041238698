import React from "react";
import Dialog from "@material-ui/core/Dialog";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import authUserOnCompany from "../../requests/authorization/authUserOnCompany";

export default function Modal(props) {
  const authUser = props.authUser ? props.authUser : {};
  const { onClose, selectedValue, open } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  async function handleListItemClick(authUser, selectedCompany) {
    try {
      const response = await authUserOnCompany(authUser, selectedCompany.id);
      localStorage.setItem("token", response.data.data.token);
      window.location.replace("/");
    } catch (error) {
      alert(error.message);
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Compañias del usuario</DialogTitle>
      <List>
        {authUser.companies.map(company => (
          <ListItem
            button
            onClick={() => handleListItemClick(authUser, company)}
            key={company.id}
          >
            <ListItemAvatar>
              <Avatar>D</Avatar>
            </ListItemAvatar>
            <ListItemText primary={company.name} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
