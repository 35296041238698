import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Divider from "@material-ui/core/Divider";

export default function OperationResume({ operation, myCompany }) {
  const CLIENT__ROLE_ID = 1;

  const mapClientAndProvider = () => {
    if (operation.setup.agreementRole.id === CLIENT__ROLE_ID) {
      return {
        client: {
          company: {
            rut: myCompany.company.rut,
            name: myCompany.company.name,
            address: myCompany.company.address,
            commune: myCompany.company.commune
          },
          legalManager: {
            rut: myCompany.legalManager.rut,
            name: myCompany.legalManager.name
          }
        },
        provider: {
          company: {
            rut: operation.counterpart.company.rut,
            name: operation.counterpart.company.name,
            address: operation.counterpart.company.address,
            commune: operation.counterpart.company.commune
          },
          legalManager: {
            rut: operation.counterpart.legalManager.rut,
            name: operation.counterpart.legalManager.name
          }
        }
      };
    }
    return {
      provider: {
        company: {
          rut: myCompany.company.rut,
          name: myCompany.company.name,
          address: myCompany.company.address,
          commune: myCompany.company.commune
        },
        legalManager: {
          rut: myCompany.legalManager.rut,
          name: myCompany.legalManager.name
        }
      },
      client: {
        company: {
          rut: operation.counterpart.company.rut,
          name: operation.counterpart.company.name,
          address: operation.counterpart.company.address,
          commune: operation.counterpart.company.commune
        },
        legalManager: {
          rut: operation.counterpart.legalManager.rut,
          name: operation.counterpart.legalManager.name
        }
      }
    };
  };

  //  const parts = mapClientAndProvider();

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Estado:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {operation.status.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Fecha de creación:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {moment(operation.createdAt).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Número:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {operation.number}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Departamento responsable:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {operation.setup.responsibleDepartment.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Responsable:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {operation.setup.responsible.name}{" "}
            {operation.setup.responsible.surname} -{" "}
            {operation.setup.responsible.email}
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Divider />
      <br />
      <Typography variant="h5" color="primary" noWrap>
        Datos del documento
      </Typography>
      <br />
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Tipo de documento:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {operation.setup.operationType.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Fecha de inicio:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {moment(operation.period.startDate).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Typography variant="h5" color="inherit" noWrap>
            Fecha de termino:
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" noWrap>
            {moment(operation.period.endDate).format("DD-MM-YYYY")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
