import React from "react";
import MaterialTable from "material-table";

export default function All({ operations = [] }) {
  const columns = [
    { title: "Fecha de ingreso", field: "period.startDate" },
    { title: "Nombre cliente", field: "contact.name" },
    { title: "Responsable", field: "setup.responsible.name" },
    { title: "Tipo de documento", field: "setup.operationType.name" },
    { title: "Estado", field: "status.name" },
    { title: "Acciones", field: "" }
  ];

  return (
    <MaterialTable
      title="Mis solicitudes creadas"
      data={operations}
      columns={columns}
    />
  );
}
