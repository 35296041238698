import axiosInstance from "../../config/axiosInstance";

export default async function uploadDocumentFileRequest({
  operationId,
  requiredDocumentLabel,
  file
}) {
  const data = new FormData();
  data.append("file", file);
  data.append("requiredDocumentLabel", requiredDocumentLabel);
  return await axiosInstance
    .dillApi()
    .put(`/operations/${operationId}/documents`, data);
}
