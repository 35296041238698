export const GET_USER_PROFILE = "GET_USER_PROFILE";
export const GET_OPERATION = "GET_OPERATION";
export const CHANGE_OPERATION_STATUS = "CHANGE_OPERATION_STATUS";
export const CHANGE_FIELD_DATA = "CHANGE_FIELD_DATA";
export const CHANGE_FIELD_STATUS = "CHANGE_FIELD_STATUS";
export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const CHANGE_DOCUMENT_STATUS = "CHANGE_DOCUMENT_STATUS";
export const SHOW_ALERT_MESSAGE = "SHOW_ALERT_MESSAGE";
export const HIDE_ALERT_MESSAGE = "HIDE_ALERT_MESSAGE";
export const MOUNT_OPERATION_FORM = "MOUNT_OPERATION_FORM";
export const COMPLETE_OPERATION_SETUP = "COMPLETE_OPERATION_SETUP";
export const COMPLETE_CUSTOMER_FORM_STEP = "COMPLETE_CUSTOMER_FORM_STEP";
export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";
export const COMPLETE_OPERATION_FORM = "COMPLETE_OPERATION_FORM";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const GET_EXPIRATION_DATES = "GET_EXPIRATION_DATES";
export const GET_OPERATIONS_BY_STATUS = "GET_OPERATIONS_BY_STATUS";
export const COMPLETE_OPERATION_PERIOD_STEP = "COMPLETE_OPERATION_PERIOD_STEP";
export const COMPLETE_REQUIRED_DOCUMENTS = "COMPLETE_REQUIRED_DOCUMENTS";
export const COMPLETE_CONTACT_DATA = "COMPLETE_CONTACT_DATA";
export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_COUNTS = "GET_COUNTS";
export const UPDATED_DOCUMENT = "UPDATED_DOCUMENT";
export const UPDATE_OPERATION = "UPDATE_OPERATION";
export const GET_COMPANIES = "GET_COMPANIES";
