import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

export default function DataPresenter(props) {
  const [open, setOpen] = useState(props.open ? props.open : false);
  const handleClose = () => setOpen(false);
  const fullWidth = props.fullWidth ? props.fullWidth : false;
  const maxWidth = props.maxWidth ? props.maxWidth : "sm";
  const buttonText = props.icon ? props.icon : <HelpIcon />;
  const buttonTitle = props.buttonTitle ? props.buttonTitle : "";
  return (
    <div>
      <Button onClick={() => setOpen(true)} title={buttonTitle}>
        {buttonText}
      </Button>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>
          {props.title} <HelpOutlineIcon />
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
      </Dialog>
    </div>
  );
}
