import { GET_COUNTS, GET_EXPIRATION_DATES, GET_COMPANIES } from "./types";
import { handle } from "../../components/errors/errorHandler";
import getEventsRequest from "../../requests/dashboard/getEventsRequest";
import { getCountsRequest } from "../../requests/dashboard/dashboardRequests";
import { getCompaniesRequest } from "../../requests/companies/CompaniesRequest";

export const getExpirationDates = () => async dispatch => {
  try {
    const response = await getEventsRequest();
    dispatch({
      type: GET_EXPIRATION_DATES,
      payload: response.data.data.map(event => {
        return {
          id: event.id,
          title: event.name,
          start: event.endDate,
          end: event.endDate
        };
      })
    });
  } catch (e) {
    handle(e);
  }
};

export const getCounts = () => async dispatch => {
  try {
    const response = await getCountsRequest();
    dispatch({
      type: GET_COUNTS,
      payload: response.data.data
    });
  } catch (e) {
    handle(e);
  }
};

export const getCompanies = () => async dispatch => {
  try {
    const response = await getCompaniesRequest();
    dispatch({
      type: GET_COMPANIES,
      payload: response.data.data
    });
  } catch (e) {
    handle(e);
  }
};
