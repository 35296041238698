import React from "react";
import Container from "@material-ui/core/Container";
const CustomerLayout = props => {
  return (
    <div>
      <Container maxWidth="lg">{props.children}</Container>
    </div>
  );
};

export default CustomerLayout;
