import React from "react";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export default function DownloadButton({
  disabled = false,
  title = "Descargar",
  onClick
}) {
  return (
    <div>
      <Button disabled={disabled} title={title} onClick={onClick}>
        <CloudDownloadIcon />
      </Button>
    </div>
  );
}
