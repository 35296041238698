import { GET_USER_PROFILE } from "./types";
import { handle } from "../../components/errors/errorHandler";
import getUserProfileRequest from "../../requests/profile/getUserProfileRequest";

export const getUserProfile = () => async dispatch => {
  try {
    const response = await getUserProfileRequest();
    dispatch({ type: GET_USER_PROFILE, payload: response.data.data });
  } catch (error) {
    handle(
      error
      /*"Ha ocurrido un problema al obtener tu perfil, intentalo más tarde"*/
    );
  }
};
