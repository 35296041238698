import axiosInstance from "../../config/axiosInstance";
import moment from "moment";

const getOperationByStatusRequest = async status => {
  const response = await axiosInstance
    .dillApi()
    .get("/operations/status/" + status);
  return response.data.data.sort((a, b) =>
    moment(a.createdAt).isAfter(b.createdAt)
  );
};

export default getOperationByStatusRequest;
