import React from "react";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";

const FirmButton = ({ disabled = false, title = "Firmar", onClick }) => {
  return (
    <Button
      disabled={disabled}
      title={title}
      onClick={onClick}
      variant="contained"
      color="primary"
      fullWidth
    >
      {title}
    </Button>
  );
};

export default FirmButton;
