import * as jwtDecode from "jwt-decode";

export const getUserId = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return false;
  }
  const payload = jwtDecode(token);

  return payload.user.id;
};
