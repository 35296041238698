import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";
import RequiredDocumentsSection from "./RequiredDocumentsSection";
import Divider from "@material-ui/core/Divider";

const RequiredDocuments = ({
  requiredDocumentsOption,
  backStep,
  onComplete
}) => {
  const [items, setItems] = React.useState(requiredDocumentsOption);

  const onCheck = event => {
    const selectedItem = event.target.value;

    const newItems = items.map(item => {
      if (item.label === selectedItem) {
        return {
          ...item,
          checked: !item.checked
        };
      }
      return item;
    });

    setItems(newItems);
  };

  return (
    <div>
      <Divider />
      <Typography variant="h6" gutterBottom>
        Documentos Requeridos
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <RequiredDocumentsSection options={items} onCheck={onCheck} />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={6} sm={6} md={2}>
          <Button onClick={backStep} variant={"contained"}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={2}>
          <Button
            onClick={() =>
              onComplete(items.filter(item => item.checked === true))
            }
            variant="contained"
            color="primary"
          >
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default RequiredDocuments;
