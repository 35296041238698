import getOperationRequest from "../../../requests/operations/getOperationRequest";
import updateOperationRequest from "../../../requests/operations/updateOperationRequest";
import uploadDocumentFileRequest from "../../../requests/operations/uploadDocumentFileRequest";
import approveOperationRequest from "../../../requests/operations/approveOperationRequest";

const GET_OPERATION_SUCCESS = "operation/getOperationSuccess";
const GET_OPERATION_FAIL = "operation/getOperationFail";

const UPDATE_OPERATION_FIELD_SUCCESS = "operation/updateOperationFieldSuccess";
const UPDATE_OPERATION_FIELD_FAIL = "operation/updateOperationFieldFail";

const UPDATE_OPERATION_FIELD_STATUS_SUCCESS =
  "operation/updateOperationFieldStatusSuccess";
const UPDATE_OPERATION_FIELD_STATUS_FAIL =
  "operation/updateOperationFieldStatusFail";

const UPDATE_DOCUMENT_STATUS_SUCCESS = "operation/updateDocumentStatusSuccess";
const UPDATE_DOCUMENT_STATUS_FAIL = "operation/updateDocumentStatusFail";

const APPROVE_OPERATION_START = "operation/approveOperationStart";
const APPROVE_OPERATION_SUCCESS = "operation/approveOperationSuccess";
const APPROVE_OPERATION_FAIL = "operation/approveOperationFail";

const REJECT_OPERATION_START = "operation/rejectOperationStart";
const REJECT_OPERATION_SUCCESS = "operation/rejectOperationSuccess";
const REJECT_OPERATION_FAIL = "operation/rejectOperationFail";

const getOperationSuccess = payload => ({
  type: GET_OPERATION_SUCCESS,
  payload
});

const getOperationFail = payload => ({
  type: GET_OPERATION_FAIL,
  payload
});

const updateOperationFieldSuccess = payload => ({
  type: UPDATE_OPERATION_FIELD_SUCCESS,
  payload
});
const updateOperationFieldFail = payload => ({
  type: UPDATE_OPERATION_FIELD_FAIL,
  payload
});

const updateOperationFieldStatusSuccess = payload => ({
  type: UPDATE_OPERATION_FIELD_STATUS_SUCCESS,
  payload
});

const updateOperationFieldStatusFail = payload => ({
  type: UPDATE_OPERATION_FIELD_STATUS_FAIL,
  payload
});

const updateDocumentStatusSuccess = payload => ({
  type: UPDATE_DOCUMENT_STATUS_SUCCESS,
  payload
});

const approveOperationStart = () => ({
  type: APPROVE_OPERATION_START
});

const approveOperationSuccess = payload => ({
  type: APPROVE_OPERATION_SUCCESS,
  payload
});

const approveOperationFail = payload => ({
  type: APPROVE_OPERATION_FAIL,
  payload
});

const rejectOperationStart = () => ({
  type: REJECT_OPERATION_START
});

const rejectOperationSuccess = payload => ({
  type: REJECT_OPERATION_SUCCESS,
  payload
});
const rejectOperationFail = payload => ({
  type: REJECT_OPERATION_FAIL,
  payload
});

const updateDocumentStatusFail = payload => ({
  type: UPDATE_DOCUMENT_STATUS_FAIL,
  payload
});

const initState = {
  operation: {
    id: "",
    companyId: "",
    internalOwner: "",
    number: 1,
    status: 1,
    createdAt: "",
    operationType: 1,
    registryType: 1,
    operationHeader: {
      agreementRole: 1,
      agreementDate: "",
      expirationDate: "",
      agreementCity: ""
    },
    operationSetup: {
      responsible: {
        id: "",
        name: "",
        surname: "",
        email: ""
      },
      reviewers: [],
      responsibleDepartment: 1
    },
    counterpart: {
      contact: {
        rut: "",
        name: "",
        email: ""
      },
      company: {
        rut: "",
        name: "",
        address: "",
        commune: ""
      },
      legalManager: {
        rut: "",
        name: ""
      }
    },
    requiredDocuments: [],
    requiredFields: []
  },
  myCompany: {
    company: {
      rut: "",
      name: "",
      address: "",
      commune: ""
    },
    legalManager: {
      rut: "",
      name: ""
    }
  },
  error: ""
};
export default function(state = initState, action) {
  switch (action.type) {
    case GET_OPERATION_SUCCESS:
      return {
        operation: action.payload.operation,
        myCompany: action.payload.myCompany
      };
    case GET_OPERATION_FAIL:
      return {
        error: action.payload,
        ...state
      };

    case UPDATE_OPERATION_FIELD_SUCCESS:
      return {
        operation: action.payload,
        ...state
      };

    case UPDATE_OPERATION_FIELD_STATUS_SUCCESS:
      return {
        ...state,
        operation: action.payload
      };

    case UPDATE_DOCUMENT_STATUS_SUCCESS:
      return {
        ...state,
        operation: action.payload
      };
    default:
      return state;
  }
}

export const getOperationAction = payload => async (dispatch, getState) => {
  try {
    const response = await getOperationRequest(payload);
    dispatch(getOperationSuccess(response.data.data));
  } catch (error) {
    dispatch(getOperationFail(error.message));
    alert(error.message);
  }
};

export const updateField = payload => async (dispatch, getState) => {
  try {
    const body = {
      ...payload,
      operationType: payload.operationType.id,
      operationSetup: {
        ...payload.operationSetup,
        reviewers: payload.operationSetup.reviewers.map(
          reviewer => reviewer.id
        ),
        operationHeader: {
          ...payload.operationHeader,
          agreementRole: payload.operationHeader.agreementRole.id
        },
        responsibleDepartment: payload.operationSetup.responsibleDepartment.id
      }
    };
    console.log("pre request operation", body);
    const response = await updateOperationRequest(body);
    console.log("post request operation", body);
    dispatch(updateOperationFieldSuccess(response.data.data));
  } catch (error) {
    console.log(error.message);
    dispatch(updateOperationFieldFail(error.message));
  }
};

export const changeFieldState = payload => async (dispatch, getState) => {
  const { operation, fieldToUpdate, newStatus } = payload;

  const newOperation = {
    ...operation,
    requiredFields: operation.requiredFields.map(requiredField => {
      if (requiredField.field.name === fieldToUpdate.name) {
        return {
          ...requiredField,
          status: newStatus.id
        };
      }
      return requiredField;
    })
  };
  const body = {
    ...newOperation,
    operationType: newOperation.operationType.id,
    operationSetup: {
      ...newOperation.operationSetup,
      reviewers: newOperation.operationSetup.reviewers.map(
        reviewer => reviewer.id
      ),
      operationHeader: {
        ...operation.operationHeader,
        agreementRole: operation.operationHeader.agreementRole.id
      },
      responsibleDepartment:
        newOperation.operationSetup.responsibleDepartment.id
    }
  };

  console.log("pre request body", body);
  try {
    const response = await updateOperationRequest(body);
    console.log("post request response", response.data.data);
    dispatch(updateOperationFieldStatusSuccess(response.data.data));
  } catch (error) {
    alert("Error al cambiar el estado, intentalo más tarde.");
    dispatch(updateOperationFieldStatusFail(error.message));
  }
};

export const changeDocumentStatus = payload => async (dispatch, getState) => {
  const { operation, documentToUpdate, newStatus } = payload;
  console.log("operation = ", operation);
  console.log("documentToUpdate = ", documentToUpdate);
  console.log("newStatus = ", newStatus);

  const newOperation = {
    ...operation,
    requiredDocuments: operation.requiredDocuments.map(requiredDocument => {
      console.log("requiredDocument label = ", requiredDocument.field.label);
      console.log("update label = ", documentToUpdate.label);
      if (requiredDocument.field.label === documentToUpdate.field.label) {
        const newDatA = {
          ...requiredDocument,
          status: newStatus.id
        };

        console.log("nuevo data", newDatA);
        return newDatA;
      }
      return requiredDocument;
    })
  };

  console.log("nueva operación", newOperation);

  const body = {
    ...newOperation,
    operationType: newOperation.operationType.id,
    operationSetup: {
      ...newOperation.operationSetup,
      reviewers: newOperation.operationSetup.reviewers.map(
        reviewer => reviewer.id
      ),
      operationHeader: {
        ...operation.operationHeader,
        agreementRole: operation.operationHeader.agreementRole.id
      },
      responsibleDepartment:
        newOperation.operationSetup.responsibleDepartment.id
    }
  };

  console.log("pre request body", body);
  try {
    const response = await updateOperationRequest(body);
    console.log("post request response", response.data.data);
    dispatch(updateDocumentStatusSuccess(response.data.data));
  } catch (error) {
    alert("Error al cambiar el estado, intentalo más tarde.");
    dispatch(updateDocumentStatusFail(error.message));
  }
};

export const uploadDocument = payload => async (dispatch, getState) => {
  const { operation, requiredDocument, file } = payload;
  console.log("operation", operation);
  console.log("requiredDocument", requiredDocument);
  console.log("file", file);

  try {
    const response = await uploadDocumentFileRequest(
      operation.id,
      requiredDocument.field.label,
      file
    );

    console.log(response.data);
    alert("El documento se ha subido correctamente.");
  } catch (error) {
    /*alert(
      "Ha ocurrido un problema al subir el documento, intentalo más tarde."
    );*/
  }
};

export const approveOperationAction = ({ operation }) => async (
  dispatch,
  getState
) => {
  dispatch(approveOperationStart());

  const APPROVED_STATUS = 2;

  try {
    console.log(operation);
    const response = await approveOperationRequest({
      operation,
      APPROVED_STATUS
    });
    console.log("respuesta", response);
  } catch (error) {}
};

export const rejectOperationAction = operation => async (
  dispatch,
  getState
) => {
  console.log("hare", operation);
};
