import React from "react";
import userOperationsRequest from "../../requests/admin/userOperationsRequest";
import UserFullTable from "../../components/admin/UserFullTable";
import addUserOperationRequest from "../../requests/admin/addUserOperationRequest";
import deleteUserRequest from "../../requests/admin/deleteUserRequest";
import updateUserRequest from "../../requests/admin/updateUserRequest";

export default class Active extends React.Component {
  state = {
    users: []
  };

  componentDidMount() {
    userOperationsRequest()
      .then(data => {
        console.log(data.data.data);
        this.setState({
          users: data.data.data
        });
      })
      .catch(e => {
        console.log(e);
      });
  }

  addUser = async newUser => {
    try {
      const response = await addUserOperationRequest({
        name: newUser.name,
        surname: newUser.surname,
        email: newUser.email
      });
      window.location.reload();
      /*      const addedUser = response.data;
      const oldUsers = this.state.users;
      oldUsers.push(addedUser);
      this.setState({ users: oldUsers });*/
    } catch (e) {
      alert(e.message);
    }
  };

  updateUser = async user => {
    try {
      const response = await updateUserRequest(user.id, {
        name: user.name,
        surname: user.surname,
        email: user.email
      });
      window.location.reload();
    } catch (e) {
      alert(e.message);
    }
  };

  deleteUser = async user => {
    try {
      await deleteUserRequest(user.id);
      window.location.reload();
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    return (
      <div>
        <UserFullTable
          users={this.state.users}
          addUser={this.addUser}
          updateUser={this.updateUser}
          deleteUser={this.deleteUser}
        />
      </div>
    );
  }
}
