import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0)
    },
    total: {
        fontWeight: "700"
    },
    title: {
        marginTop: theme.spacing(2)
    }
}));
