import React from "react";
import { connect } from "react-redux";
import Panel from "../../../components/Panel";
import OperationResume from "./OperationResume";
import Grid from "@material-ui/core/Grid";
import OperationActions from "./OperationActions";
import RequiredDocumentsActions from "./RequiredDocumentsActions";
import Typography from "@material-ui/core/Typography";
import {
  addObservationToField,
  addObservationToFile,
  changeDocumentStatus,
  changeFieldData,
  changeFieldStatus,
  changeOperationStatus,
  getDocument,
  getOperation,
  uploadDocument
} from "../../../stores/actions/operationActions";
import DocumentData from "../../../components/operations/DocumentData";
import FirmButton from "../../../components/ui/buttons/FirmButton";
import Divider from "@material-ui/core/Divider";
import OperationActionDownload from "./OperationActionDownload";

class ViewOperation extends React.Component {
  componentDidMount() {
    this.props.getOperation(this.props.match.params.operationId);
    this.props.getDocument(this.props.match.params.operationId);
  }

  render() {
    const {
      operation,
      myCompany,
      changeOperationStatus,
      changeFieldData,
      changeFieldStatus,
      changeDocumentStatus,
      uploadDocument,
      document,
      addObservationToField,
      addObservationToFile
    } = this.props;

    console.log("QUE ES ?", operation.status);

    if (operation.status.id !== 2) {
      return (
        <div>
          <Panel title="Acciones de la operación">
            <OperationActions
              operation={operation}
              myCompany={myCompany}
              changeOperationStatus={changeOperationStatus}
            />
          </Panel>
          <p />
          <Panel>
            <Typography variant="h5" color="primary" noWrap>
              Datos de la operación
            </Typography>

            <br />
            <br />
            <OperationResume operation={operation} myCompany={myCompany} />
          </Panel>
          <p />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <Panel title={document.meta.name}>
                <DocumentData
                  document={document}
                  changeFieldData={changeFieldData}
                  changeFieldStatus={changeFieldStatus}
                  addObservation={addObservationToField}
                />
              </Panel>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Panel title="Documentación solicitada">
                <RequiredDocumentsActions
                  changeDocumentStatus={changeDocumentStatus}
                  uploadDocument={uploadDocument}
                  operation={operation}
                  addObservation={addObservationToFile}
                />
              </Panel>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div>
          <Panel>
            <Typography variant="h5" color="primary" noWrap>
              Datos de la operación
            </Typography>

            <br />
            <br />
            <OperationResume operation={operation} myCompany={myCompany} />
            <Divider />
            <br />
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Grid container>
                  <Grid xs={10}></Grid>
                  <Grid xs={12} md={2}>
                    <OperationActionDownload operation={operation} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Panel>

          <p />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Panel>
                <Grid container>
                  <Grid xs={10}></Grid>
                  <Grid xs={12} md={2}>
                    <FirmButton title="Firmar" />
                  </Grid>
                </Grid>
              </Panel>
            </Grid>
          </Grid>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  console.log("estado view", state.operation.document);
  return {
    operation: state.operation.operation,
    document: state.operation.document,
    myCompany: state.operation.myCompany
  };
};

const mapDispatchToProps = dispatch => ({
  getOperation: payload => dispatch(getOperation(payload)),
  getDocument: payload => dispatch(getDocument(payload)),
  changeOperationStatus: payload => dispatch(changeOperationStatus(payload)),
  changeFieldData: payload => dispatch(changeFieldData(payload)),
  changeFieldStatus: payload => dispatch(changeFieldStatus(payload)),
  changeDocumentStatus: payload => dispatch(changeDocumentStatus(payload)),
  uploadDocument: payload => dispatch(uploadDocument(payload)),
  addObservationToField: payload => dispatch(addObservationToField(payload)),
  addObservationToFile: payload => dispatch(addObservationToFile(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewOperation);
