import React from "react";
import { Field, Form, Formik } from "formik";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import Button from "@material-ui/core/Button";
import * as Yup from "yup";

const contactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Muy corto!")
    .max(50, "Muy largo!"),

  email: Yup.string().email("Email invalido")
});

const ContactData = ({ contact, backStep, onComplete }) => {
  return (
    <div>
      <Formik
        is
        initialValues={{
          name: contact.name,
          email: contact.email,
          phone: contact.phone
        }}
        validationSchema={contactSchema}
        onSubmit={values => {
          onComplete(values);
        }}
      >
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Field
                variant="outlined"
                margin="normal"
                fullWidth
                id="name"
                label="Nombre"
                name="name"
                autoComplete="name"
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                variant="outlined"
                margin="normal"
                fullWidth
                id="phone"
                label="Telefono de contacto"
                name="phone"
                autoComplete="phone"
                component={TextField}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container>
            <Grid item xs={6} sm={6} md={2}>
              <Button onClick={backStep} variant={"contained"}>
                Atrás
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={2}>
              <Button type="submit" variant="contained" color="primary">
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactData;
