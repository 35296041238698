import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { toNaturalSentence } from "stringr.js";

export default function RequiredDocumentsSection({ options = [], onCheck }) {
  return (
    <div>
      <FormControl component="fieldset">
        <FormGroup>
          {options.map(items => {
            return (
              <FormControlLabel
                key={items.label}
                control={
                  <Checkbox
                    checked={items.checked}
                    onChange={event => onCheck(event)}
                    value={items.label}
                  />
                }
                label={toNaturalSentence(items.label)}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </div>
  );
}
