import React from "react";
import { makeStyles } from "@material-ui/core/styles/index";
import MenuItem from "@material-ui/core/MenuItem/index";
import FormControl from "@material-ui/core/FormControl/index";
import OutlinedInput from "@material-ui/core/OutlinedInput/index";
import TextField from "@material-ui/core/TextField/index";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  inputColor: {
    color: "black"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export default function CustomSelect({
  options = [],
  label = "Selecciona",
  value = "",
  handleChange
}) {
  const classes = useStyles();

  return (
    <FormControl className={classes.container} noValidate autoComplete="off">
      <TextField
        select
        label={label}
        value={value}
        className={classes.textField}
        onChange={handleChange}
        margin="normal"
        variant="outlined"
        input={<OutlinedInput labelWidth={0} />}
      >
        {options.map(option => {
          return (
            <MenuItem key={option.id} value={option}>
              {option.name}
            </MenuItem>
          );
        })}
        />
      </TextField>
    </FormControl>
  );
}
