import axiosInstance from "../config/axiosInstance";

export const getDocumentRequest = async operationId => {
  return await axiosInstance
    .dillApi()
    .get(`/operations/${operationId}/document`);
};
export const updateDocumentRequest = async document => {
  return await axiosInstance
    .dillApi()
    .patch(`/operations/documents/${document.id}`, document);
};
