import React from "react";
import RequiredDataCustomer from "../operations/viewOperation/RequiredDataCustomer";
import { Container, Typography } from "@material-ui/core";
import Panel from "../../components/Panel";
import OperationActions from "../operations/viewOperation/OperationActions";
import OperationResume from "../operations/viewOperation/OperationResume";
import Grid from "@material-ui/core/Grid";
import RequiredData from "../operations/viewOperation/RequiredData";
import RequiredDocumentsActions from "../operations/viewOperation/RequiredDocumentsActions";
import IconViewDocumentAttachment from "../../components/IconViewDocumentAttachment";
import IconDocumentUpload from "../../components/IconDocumentUpload";
import CustomSelect from "../../components/ui/selects/CustomSelect";
import FieldRevisionManager from "../operations/viewOperation/FieldRevisionManager";

const ViewDocument = class ViewOperation extends React.Component {
  render() {
    return (
      <div>
        <Container maxWidth="lg">
          <Typography variant="h2" color="primary" noWrap>
            Formulario de Solicitud de Información
          </Typography>
          <br />
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <Panel title=""></Panel>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Panel title="Documentación solicitada">
                  <Grid container>
                    <Grid item xs={12} sm={12} md={8}>
                      <Typography variant="h6" color="textPrimary">
                        Pasaporte
                      </Typography>
                      <Grid container>
                        <Grid>
                          <IconViewDocumentAttachment />
                        </Grid>
                        <Grid>
                          <IconDocumentUpload />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FieldRevisionManager />
                    </Grid>
                  </Grid>
                </Panel>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    );
  }
};

export default ViewDocument;
