import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import CustomSelect from "../../../components/ui/selects/CustomSelect";
import Button from "@material-ui/core/Button";
import { useAlert } from "react-alert";
import SelectedReviewers from "../../../components/operations/form/SelectedReviewers";
import ReviewerPicker from "../../../components/operations/form/ReviewerPicker";

const OperationSetup = ({
  setup,
  agreementRoles,
  users,
  operationTypes,
  departments,
  registryTypes,
  onComplete
}) => {
  const [setupData, setSetupData] = useState(setup);
  const [openAddReviewers, setOpenAddReviewers] = useState(false);

  /*  const onAddTitle = event => {
    setSetupData({
      ...setupData,
      title: event.target.value
    });
  };*/

  const onSelectRegistryType = event => {
    setSetupData({
      ...setupData,
      registryType: event.target.value
    });
  };

  const addReviewer = selectedReviewer => {
    setSetupData({
      ...setupData,
      reviewers: [...setupData.reviewers, selectedReviewer]
    });
  };

  const removeReviewer = toRemove => {
    setSetupData({
      ...setupData,
      reviewers: setupData.reviewers.filter(
        reviewer => reviewer.user.id !== toRemove.user.id
      )
    });
  };

  const editReviewer = toEdit => {
    setSetupData({
      ...setupData,
      reviewers: setupData.reviewers.map(reviewer => {
        if (reviewer.user.id === toEdit.user.id) {
          return toEdit;
        }
        return reviewer;
      })
    });
  };

  const onSelectOperationType = event => {
    setSetupData({
      ...setupData,
      operationType: event.target.value
    });
  };

  const onSelectResponsibleDepartment = event => {
    setSetupData({
      ...setupData,
      responsibleDepartment: event.target.value
    });
  };

  const onSelectAgreementRole = event => {
    setSetupData({
      ...setupData,
      agreementRole: event.target.value
    });
  };
  const messenger = useAlert();

  const handleSubmit = () => {
    /*    if (setupData.title === "") {
      messenger.error("Debes escribir minimo 1 letra.");
      return;
    }*/

    if (!setupData.agreementRole.id) {
      messenger.error("Debes seleccionar la comuna de suscripcion.");
      return;
    }

    if (!setupData.registryType.id) {
      messenger.error("Debes seleccionar un tipo de registro.");
      return;
    }

    if (!setupData.operationType.id) {
      messenger.error("Debes seleccionar un tipo de operación.");
      return;
    }

    if (!setupData.responsibleDepartment.id) {
      messenger.error("Debes seleccionar un departamento responsable.");
      return;
    }

    console.log("setupData", setupData);

    onComplete(setupData);
  };

  return (
    <div>
      <Grid container spacing={3}>
        {/*        <Grid item xs={12} sm={12} md={12}>
          <FormHelperText id="component-helper-text">
            Ingrese un títutlo para identificar la operación
          </FormHelperText>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="title"
            label="Título"
            name="title"
            autoComplete="title"
            value={setupData.title}
            onChange={onAddTitle}
          />
        </Grid>*/}
        <Grid item xs={12} sm={12} md={6}>
          <CustomSelect
            label="Mi rol de empresa dentro del contrato"
            value={setupData.agreementRole}
            options={agreementRoles}
            handleChange={onSelectAgreementRole}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomSelect
            label="Tipo de registro"
            value={setupData.registryType}
            options={registryTypes}
            handleChange={onSelectRegistryType}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomSelect
            label="Tipo de operación"
            value={setupData.operationType}
            options={operationTypes}
            handleChange={onSelectOperationType}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <CustomSelect
            label="Departamento responsable"
            value={setupData.responsibleDepartment}
            options={departments}
            handleChange={onSelectResponsibleDepartment}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <SelectedReviewers
            selectedReviewers={setupData.reviewers}
            editReviewer={editReviewer}
            removeReviewer={removeReviewer}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ReviewerPicker
            open={openAddReviewers}
            onClose={() => setOpenAddReviewers(false)}
            users={users}
            addReviewer={addReviewer}
          />
          <Button
            onClick={() => {
              setOpenAddReviewers(true);
            }}
          >
            Añadir Revisor
          </Button>
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Siguiente
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default OperationSetup;
