import axiosInstance from "../../config/axiosInstance";

const mapToBody = operation => {
  console.log("operaciones", operation);
  return {
    ...operation,
    status: operation.status.id,
    setup: {
      ...operation.setup,
      agreementRole: operation.setup.agreementRole.id,
      responsibleDepartment: operation.setup.responsibleDepartment.id,
      responsible: operation.setup.responsible.id,
      reviewers: operation.setup.reviewers.map(reviewer => {
        console.log("reviewer", reviewer);
        return { ...reviewer, user: { id: reviewer.user.id } };
      }),
      operationType: operation.setup.operationType.id
    }
  };
};

export default async operation => {
  const body = mapToBody(operation);
  console.log("body", body);
  return await axiosInstance
    .dillApi()
    .patch(`/operations/${operation.id}`, body);
};
