import axios from "axios";

const headers = {
  Authorization: "Bearer " + localStorage.getItem("token")
};

const prodEndpoint = "https://zl18qg6zh6.execute-api.us-east-1.amazonaws.com/develop/";
//const prodEndpoint = "http://ec2-34-224-70-171.compute-1.amazonaws.com/";
const localEndpoint = "http://localhost:3000";

const isUnauthorized = error => error.response.status !== 401;

const dillApi = () => {
  const axiosInstance = axios.create({
    baseURL: prodEndpoint,
    timeout: 300000,
    headers: headers
  });
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (isUnauthorized(error)) {
        return error;
      }
      localStorage.clear();
      /*alert("Tu sesion ha expirado, debes iniciar sesion nuevamente");*/
      window.location.replace("/signin");
    }
  );
  return axiosInstance;
};

export default {
  dillApi
};
