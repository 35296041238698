import axiosInstance from "../../config/axiosInstance";

const updateUserRequest = async (id, body) => {
  const response = await axiosInstance
    .dillApi()
    .patch("/admin/users/" + id, body);
  return response;
};

export default updateUserRequest;
