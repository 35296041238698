import React, { useEffect, useState } from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Grid from "@material-ui/core/Grid";
import CustomSelect from "../../../components/ui/selects/CustomSelect";

export default function IsolateTextField(props) {
  const [state, setState] = useState({
    isLocked: props.field.value.length > 0,
    field: props.field,
    isPending: props.isPending
  });

  const handleEdit = action => {
    if (!state.isLocked) {
      setState({
        ...state,
        isLocked: !state.isLocked
      });
      action(state.field);
    }
    setState({
      ...state,
      isLocked: !state.isLocked
    });
  };

  const stateOptions = [
    { id: 1, name: "Pendiente" },
    { id: 2, name: "Aprobada" },
    { id: 3, name: "Rechazada" }
  ];

  return (
    <div>
      <Grid container>
        <Grid item sm={12} md={8}>
          {console.log("estado", state.isPending)}
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="test"
            label={state.field.label}
            value={state.field.value}
            onChange={event => {
              setState({
                ...state,
                field: {
                  ...state.field,
                  value: event.target.value
                }
              });
            }}
            autoComplete="projectName"
            disabled={state.isLocked}
            error={state.isPending}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="toggle password visibility"
                    onClick={() => handleEdit(props.handleClick)}
                  >
                    {state.isLocked ? <LockIcon /> : <LockOpenIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item sm={12} md={4}>
          {console.log(props.field)}
          <CustomSelect
            options={stateOptions}
            value={stateOptions.find(option => option.id === props.status)}
            handleChange={event =>
              props.handleChange(state.field, event.target.value)
            }
          />
        </Grid>
      </Grid>
    </div>
  );
}
