import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  input: {
    display: "none"
  }
}));

export default function IconDocumentUpload({ onChange }) {
  const classes = useStyles();
  return (
    <div>
      <input
        accept="image/*"
        className={classes.input}
        id="text-button-file"
        type="file"
        onChange={onChange}
      />
      <label htmlFor="text-button-file">
        <Button component="span">
          <i className="material-icons">cloud_upload</i>
        </Button>
      </label>
    </div>
  );
}
