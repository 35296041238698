import * as jwtDecode from "jwt-decode";

const getTokenPayload = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return false;
  }
  return jwtDecode(token);
};

export const hasAdminRole = () => {
  const payload = getTokenPayload();
  if (!payload) {
    return false;
  }
  return payload.user.role === 1;
};

const isResponsible = (userId, operation) => {
  return userId === operation.setup.responsible.id;
};

export const canApproveOperation = operation => {
  console.log("here");
  const payload = getTokenPayload();

  if (!payload) {
    return false;
  }

  if (hasAdminRole()) {
    return true;
  }

  return isResponsible(payload.user.id, operation);
};
