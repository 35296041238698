import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styles from "./stylesFileResume";
import Button from "@material-ui/core/Button";
import { toNaturalSentence } from "stringr.js";
import { formatDate } from "../../../services/dateService";
import Box from "@material-ui/core/Box";

const FileResume = ({ formValues, backStep, completeOperationForm }) => {
  const classes = styles;

  return (
    <div>
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Configuración de la operación
        </Typography>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.listItem}>
              <Box fontWeight="fontWeightRegular">Rol de la empresa:</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.setup.agreementRole.name}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Tipo de registro:</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.setup.registryType.name}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Tipo de operación:</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.setup.operationType.name}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">
                Departamento responsable:
              </Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.setup.responsibleDepartment.name}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Revisores:</Box>
            </Typography>
            {formValues.setup.reviewers.map(reviewer => {
              return (
                <Typography>
                  <Box fontWeight="fontWeightLight" m={1}>
                    {reviewer.user.name} {reviewer.user.surname}
                  </Box>
                </Typography>
              );
            })}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Fecha de ingreso:</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formatDate(formValues.period.startDate)}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Fecha de expiracion:</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formatDate(formValues.period.endDate)}
              </Box>
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Documentos requeridos:
            </Typography>
            {formValues.requiredDocuments.map(requiredDocument => {
              return (
                <Typography>
                  <Box fontWeight="fontWeightLight" m={1}>
                    {toNaturalSentence(requiredDocument.label)}
                  </Box>
                </Typography>
              );
            })}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              Datos del contacto
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Nombre</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.contact.name}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Correo electrónico</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.contact.email}
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom className={classes.title}>
              <Box fontWeight="fontWeightRegular">Telefono de contacto</Box>
            </Typography>
            <Typography gutterBottom>
              <Box fontWeight="fontWeightLight" m={1}>
                {formValues.contact.phone}
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
      <br />
      <Grid container>
        <Grid item xs={6} sm={6} md={2}>
          <Button onClick={backStep} variant={"contained"}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} sm={6} md={10}>
          <Button
            variant="contained"
            onClick={completeOperationForm}
            color="primary"
          >
            Confirmar operación
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default FileResume;
