import {
  CHANGE_DOCUMENT_STATUS,
  CHANGE_FIELD_DATA,
  CHANGE_FIELD_STATUS,
  CHANGE_OPERATION_STATUS,
  GET_DOCUMENT,
  GET_OPERATION,
  GET_OPERATIONS_BY_STATUS,
  UPDATE_OPERATION,
  UPDATED_DOCUMENT,
  UPLOAD_DOCUMENT
} from "../actions/types";

const initialState = {
  operations: [],
  operation: {
    id: "",
    companyId: "",
    number: 1,
    status: 1,
    createdAt: "",
    setup: {
      responsible: { id: "", name: "", surname: "", email: "" },
      agreementRole: 1,
      operationType: 1,
      registryType: 1,
      responsibleDepartment: 1,
      reviewers: []
    },
    period: {
      startDate: Date.now(),
      endDate: Date.now(),
      alertDate: Date.now()
    },
    contact: {
      name: "",
      email: "",
      phone: ""
    },
    requiredDocuments: []
  },
  myCompany: {
    company: {
      rut: "",
      name: "",
      address: "",
      commune: ""
    },
    legalManager: {
      rut: "",
      name: ""
    }
  },
  document: {
    id: "",
    operationId: "",
    meta: {
      name: "",
      info: ""
    },
    fields: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_OPERATION:
      return {
        ...state,
        operation: action.payload.operation,
        myCompany: action.payload.myCompany
      };
    case CHANGE_OPERATION_STATUS:
      return { ...state, operation: action.payload };
    case CHANGE_FIELD_DATA:
      return { ...state, operation: action.payload };

    case CHANGE_FIELD_STATUS:
      return { ...state, document: action.payload };
    case CHANGE_DOCUMENT_STATUS:
      return { ...state, operation: action.payload };
    case UPLOAD_DOCUMENT:
      return { ...state, operation: action.payload };
    case UPDATED_DOCUMENT:
      return { ...state, document: action.payload };
    case GET_OPERATIONS_BY_STATUS:
      return {
        ...state,
        operations: action.payload
      };
    case GET_DOCUMENT:
      return {
        ...state,
        document: action.payload
      };
    case UPDATE_OPERATION:
      return {
        ...state,
        operation: action.payload
      };
    default:
      return state;
  }
}
