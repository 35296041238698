import React from "react";
import {Link as RouterLink} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles/index";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemIcon from "@material-ui/core/ListItemIcon/index";
import ListItemText from "@material-ui/core/ListItemText/index";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import HomeIcon from "@material-ui/icons/Home";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    nested: {
        paddingLeft: theme.spacing(4)
    }
}));

export default function NestedList() {
    const classes = useStyles();

    return (
        <List className={classes.root}>
            <ListItem>
                <ListItemIcon>
                    <HomeIcon/>
                </ListItemIcon>
                <Link component={RouterLink} to="/">
                    <ListItemText primary="Panel"/>
                </Link>
            </ListItem>
            <ListItem>
                <ListItemIcon>
                    <SupervisedUserCircleIcon/>
                </ListItemIcon>
                <Link component={RouterLink} to="/admin/users">
                    <ListItemText primary="Usuarios"/>
                </Link>
            </ListItem>
        </List>
    );
}
