import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FieldRevisionManager from "./FieldRevisionManager";
import CustomSelect from "../../../components/ui/selects/CustomSelect";
import IconViewDocumentAttachment from "../../../components/IconViewDocumentAttachment";
import IconDocumentUpload from "../../../components/IconDocumentUpload";
import { toNaturalSentence } from "stringr.js";

const RequiredDocumentsActions = ({
  operation,
  uploadDocument,
  changeDocumentStatus,
  addObservation
}) => {
  const stateOptions = [
    { id: 1, name: "Pendiente" },
    { id: 2, name: "Aprobada" },
    { id: 3, name: "Rechazada" }
  ];

  const onChangeDocumentStatus = (documentToUpdate, newStatus) => {
    changeDocumentStatus({ operation, documentToUpdate, newStatus });
  };

  const onUploadDocument = (requiredDocument, file) => {
    console.log("here", requiredDocument);
    uploadDocument({ operation, requiredDocument, file });
  };

  return (
    <div>
      {operation.requiredDocuments.map(requiredDocument => {
        return (
          <Grid key={requiredDocument.field.label} container>
            <Grid item xs={12} sm={12} md={8}>
              <Typography variant="h6" color="textPrimary">
                {toNaturalSentence(requiredDocument.field.label)}
              </Typography>
              <Grid container>
                <Grid>
                  <IconViewDocumentAttachment
                    documentUrl={requiredDocument.field.value}
                  />
                </Grid>
                <Grid>
                  <IconDocumentUpload
                    onChange={event =>
                      onUploadDocument(requiredDocument, event.target.files[0])
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <CustomSelect
                options={stateOptions}
                value={stateOptions.find(
                  option => option.id === requiredDocument.status
                )}
                handleChange={event =>
                  onChangeDocumentStatus(requiredDocument, event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <FieldRevisionManager
                observations={requiredDocument.observations}
                addObservation={observation =>
                  addObservation({
                    operation,
                    requiredDocument,
                    newObservation: observation
                  })
                }
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
};

export default RequiredDocumentsActions;
