import React from "react";
import DownloadButton from "../../../components/ui/buttons/DownloadButton";
import downloadRequest from "../../../requests/operations/downloadDocument";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import LinksDownload from "../../../components/LinksDownload";

export default function OperationActions({ operation, title }) {
  const handleDownload = async operationId => {
    try {
      const response = await downloadRequest(operationId);
      console.log("AQUI", response);
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      alert(error);
    }
  };

  const isApproved = operation => operation.status.id === 2;

  return (
    <div>
      <Grid container>
        <Grid>
          <LinksDownload
            title="DESCARGAR CONTRATO"
            disabled={!isApproved(operation)}
            onClick={async () => await handleDownload(operation.id)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
