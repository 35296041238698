import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import signinRequest from "../../requests/authorization/signinRequest";
import CompaniesList from "./CompaniesList";

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),

  password: Yup.string()
    .min(6, "Too Short!")
    .required("Required")
});

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const signIn = async ({ email, password }) => {
  return await signinRequest({ email, password });
};

export default function SignIn(props) {
  const classes = useStyles();

  const [openCompaniesModal, setOpenCompaniesModal] = React.useState(false);

  const [authUser, setAuthUser] = React.useState({
    user: {},
    companies: []
  });

  const onSubmit = async ({ values, resetForm }) => {
    try {
      const response = await signIn({
        email: values.email,
        password: values.password
      });

      const authUser = response.data.user;

      if (authUser.companies) {
        setAuthUser(authUser);
        setOpenCompaniesModal(true);
      } else {
        alert("wait");
      }
    } catch (error) {
      alert(
        "Ups... la plataforma no esta disponible en estos momentos, intentalo más tarde"
      );
      resetForm();
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <CompaniesList
        open={openCompaniesModal}
        authUser={authUser}
        onClose={() => {
          setOpenCompaniesModal(false);
        }}
      />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Inicio de Sesión
        </Typography>

        <Formik
          initialValues={{
            email: "",
            password: ""
          }}
          validationSchema={SigninSchema}
          onSubmit={(values, { resetForm }) =>
            onSubmit({ values, resetForm, props })
          }
        >
          <Form className={classes.form} noValidate>
            <Field
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              type="email"
              label="Email"
              name="email"
              autoComplete="identifier"
              component={TextField}
            />

            <Field
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              component={TextField}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Iniciar sesión
            </Button>
          </Form>
        </Formik>
      </div>
    </Container>
  );
}
