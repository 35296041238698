import React from "react";
import Link from "@material-ui/core/Link";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

export default function ButtonLink({ disabled = false, title, onClick }) {
  return (
    <div>
      <Button disabled={disabled} title onClick={onClick} color="primary">
        <CloudDownloadIcon /> &nbsp;
        {title}
      </Button>
    </div>
  );
}
