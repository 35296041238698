import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logging from "./middlewares/loggingMiddleware";
import rootReducer from "./reducers/rootReducer";

const initialState = {};

const middlewares = [thunk, logging];

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
);

export default store;
