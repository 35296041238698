import React from "react";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";

const ApproveButton = ({ disabled = false, title = "Aprobar", onClick }) => {
  return (
    <Button disabled={disabled} title={title} onClick={onClick}>
      <CheckIcon color={"primary"} />
    </Button>
  );
};

export default ApproveButton;
