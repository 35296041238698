import React from "react";
import List from "@material-ui/core/List";
import Observation from "./Observation";

const ObservationList = ({ observations = [] }) => {
  const byDateDesc = (aDate, anotherDate) =>
    new Date(anotherDate.createdAt) - new Date(aDate.createdAt);

  return (
    <List>
      {observations.sort(byDateDesc).map(observation => {
        return <Observation observation={observation} />;
      })}
    </List>
  );
};

export default ObservationList;
