import { combineReducers } from "redux";

import authReducer from "./authReducer";
import oldOperationsReducer from "./operation/index";
import operationReducer from "./operationReducer";
import profileReducer from "./profileReducer";
import operationFormReducer from "./operationFormReducer";
import loaderReducer from "./loaderReducer";
import dashboardReducer from "./dashboardReducer";

export default combineReducers({
  auth: authReducer,
  operations: oldOperationsReducer,
  operation: operationReducer,
  profile: profileReducer,
  operationForm: operationFormReducer,
  loader: loaderReducer,
  dashboard: dashboardReducer
});
