import {
  BACK_STEP,
  COMPLETE_CONTACT_DATA,
  COMPLETE_CUSTOMER_FORM_STEP,
  COMPLETE_OPERATION_PERIOD_STEP,
  COMPLETE_OPERATION_SETUP,
  COMPLETE_REQUIRED_DOCUMENTS,
  MOUNT_OPERATION_FORM,
  NEXT_STEP
} from "../actions/types";
import { getNow } from "../../services/dateService";

const initialState = {
  formValues: {
    setup: {
      title: "",
      agreementRole: { id: "", name: "" },
      registryType: { id: "", name: "" },
      operationType: { id: "", name: "" },
      responsibleDepartment: { id: "", name: "" },
      reviewers: []
    },
    period: {
      startDate: getNow(),
      endDate: getNow(),
      alertDate: getNow()
    },
    requiredDocuments: [],
    contact: { name: "", email: "", phone: "" }
  },
  formData: {
    registryTypes: [],
    operationTypes: [],
    companyUsers: [],
    departments: [],
    agreementRoles: [],
    requiredDocumentsOptions: [
      { checked: false, label: "cedulaDeIdentidad" },
      { checked: false, label: "pasaporte" },
      { checked: false, label: "rutDeEmpresa" },
      {
        checked: false,
        label: "idDeLosApoderados"
      },
      {
        checked: false,
        label: "antecedentesLegalesCompletos"
      },
      {
        checked: false,
        label: "poderesLegalesVigentes"
      },
      {
        checked: false,
        label: "copiaDeLaInscripciónDeLaSociedad"
      }
    ]
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MOUNT_OPERATION_FORM:
      return {
        ...state,
        formData: {
          ...state.formData,
          title: action.payload.title,
          registryTypes: action.payload.registryTypes,
          operationTypes: action.payload.operationTypes,
          companyUsers: action.payload.companyUsers,
          departments: action.payload.departments,
          cities: action.payload.cities,
          agreementRoles: action.payload.agreementRoles
        }
      };
    case COMPLETE_OPERATION_SETUP:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          setup: action.payload
        }
      };
    case COMPLETE_OPERATION_PERIOD_STEP:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          period: action.payload
        }
      };
    case COMPLETE_REQUIRED_DOCUMENTS:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          requiredDocuments: action.payload
        }
      };
    case COMPLETE_CONTACT_DATA:
      return {
        ...state,
        formValues: {
          ...state.formValues,
          contact: action.payload
        }
      };
    case NEXT_STEP:
      return {
        ...state,
        activeStep: state.activeStep + 1
      };
    case BACK_STEP:
      return {
        ...state,
        activeStep: state.activeStep - 1
      };
    default:
      return state;
  }
}
