import {
  GET_COUNTS,
  GET_EVENTS,
  GET_EXPIRATION_DATES,
  GET_COMPANIES
} from "../actions/types";

const initialState = {
  counts: { pending: 0, approved: 0, rejected: 0, assignedToMe: 0 },
  events: [],
  companies: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_EXPIRATION_DATES:
      return { ...state, events: action.payload };
    case GET_COUNTS:
      return {
        ...state,
        counts: action.payload
      };
    case GET_COMPANIES:
      return { ...state, companies: action.payload };
    default:
      return state;
  }
}
