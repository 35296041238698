import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles/index";
import List from "@material-ui/core/List/index";
import ListItem from "@material-ui/core/ListItem/index";
import ListItemIcon from "@material-ui/core/ListItemIcon/index";
import ListItemText from "@material-ui/core/ListItemText/index";
import HomeIcon from "@material-ui/icons/Home";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import Link from "@material-ui/core/Link";
import { hasAdminRole } from "../../router/guards";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

export default function NestedList({ user }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div>
      <List className={classes.root}>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link component={RouterLink} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              Panel
            </Link>
          </ListItem>
        </List>
        {hasAdminRole() && (
          <ListItem>
            <ListItemIcon>
              <SupervisedUserCircleIcon />
            </ListItemIcon>
            <Link component={RouterLink} to="/admin">
              <ListItemText primary="Admin" />
            </Link>
          </ListItem>
        )}

        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link component={RouterLink} to="/all-operations">
              <ListItemIcon>
                <WbIncandescentIcon />
              </ListItemIcon>
              Creadas
            </Link>
          </ListItem>
        </List>

        <List component="div" disablePadding>
          <ListItem button className={classes.nested} disabled>
            <Link component={RouterLink} to="#!">
              <ListItemIcon>
                <ArrowForwardIcon />
              </ListItemIcon>
              Asignadas
            </Link>
          </ListItem>
        </List>

        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link component={RouterLink} to="/pending-operations">
              <ListItemIcon>
                <AccessAlarmIcon />
              </ListItemIcon>
              Pendientes
            </Link>
          </ListItem>
        </List>

{/*        <List component="div" disablePadding>
          <ListItem button className={classes.nested} disabled>
            <Link component={RouterLink} to="#!">
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              Pendientes de firma
            </Link>
          </ListItem>
        </List>*/}

        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link component={RouterLink} to="/approved-operations">
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              Pendientes de firma
            </Link>
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <Link component={RouterLink} to="/rejected-operations">
              <ListItemIcon>
                <ClearIcon />
              </ListItemIcon>
              Rechazadas
            </Link>
          </ListItem>
        </List>

        <List component="div" disablePadding>
          <ListItem button className={classes.nested} disabled>
            <Link component={RouterLink} to="#!">
              <ListItemIcon>
                <AssessmentOutlinedIcon />
              </ListItemIcon>
              Reporte
            </Link>
          </ListItem>
        </List>
      </List>
    </div>
  );
}
