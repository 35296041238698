import React from "react";
import OperationsResume from "./OperationsResume";
import TableOperations from "./TableOperations";
import Grid from "@material-ui/core/Grid";
import Calendar from "../../components/calendar/Calendar";
import {
  getCounts,
  getExpirationDates,
  getCompanies
} from "../../stores/actions/dashboardActions";
import { connect }   from "react-redux";
import { getOperationsByStatus } from "../../stores/actions/operationActions";
import { Paper } from "@material-ui/core";

class Dashboard extends React.Component {
  state = {
    operationResumeCount: {
      active: 0,
      approved: 0,
      pending: 0,
      rejected: 0,
      toCheck: 0,
      assigned: 0,
      created: 0
    },
    operationsResume: {
      active: [],
      approved: [],
      pending: [],
      rejected: [],
      toCheck: [],
      assigned: [],
      created: []
    }
  };

  PENDING_STATUS_FLAG = 1;

  componentDidMount() {
    this.props.getExpirationDates();
    this.props.getCounts();
    this.props.getCompanies();
    /* llamar a dashboard */
    this.props.getOperationsByStatus(this.PENDING_STATUS_FLAG);
  }

  render() {
    const { events, counts, operations, companies } = this.props;
    console.log("COMPANIES", companies);
    const { onSelectEvent } = this.props;

    return (
      <div>
        <OperationsResume counts={counts} />
        <br />
        <Paper>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TableOperations
                title={"Próximas alertas"}
                operations={operations.reverse().slice(0, 3)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Calendar
                events={events}
                onSelectEvent={event =>
                  window.location.replace(`/operations/${event.id}`)
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    counts: state.dashboard.counts,
    events: state.dashboard.events,
    companies: state.dashboard.companies,
    operations: state.operation.operations
  };
};
export default connect(
  mapStateToProps,
  { getExpirationDates, getCounts, getOperationsByStatus, getCompanies }
)(Dashboard);
