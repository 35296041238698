import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Title from "../../components/Title";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    padding: "20px",
    // background: "#F5F5F5"
    marginLeft: "10px"
  },
  table: {
    minWidth: 300
  },
  box: {
    background: "#FFFFFF"
  },
  seeMore: {
    marginTop: theme.spacing(3)
  }
}));

export default function SimpleTable(props) {
  const classes = useStyles();

  const operations = props.operations;

  /*const company = props.com;*/

  /*
    Nombre de la empresa ???
    De donde sale el nombre de la empresa ?
    Revisar este tema con Marco
  */

  return (
    <Paper className={classes.root}>
      <div className={classes.box}>
        <Title>{props.title}</Title>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Fecha Expiración</TableCell>
              <TableCell align="center">Nº</TableCell>
              <TableCell align="center">Empresa</TableCell>
              <TableCell align="center">Tipo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operations.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {moment(row.period.endDate).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="center">{row.number}</TableCell>
                <TableCell align="center">{row.contact.name}</TableCell>
                <TableCell align="center">
                  {row.setup.agreementRole.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}
