import React, { useEffect, useState } from "react";
import InfoPresenter from "./ui/dialogs/InfoPresenter";
import { Typography } from "@material-ui/core";
import getUploadedDocument from "../requests/operations/getUploadedDocument";

export default function IconDocumentUpload({ documentUrl }) {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (!documentUrl) {
      return;
    }
    getUploadedDocument(documentUrl)
      .then(response => {
        console.log(response.data);
        setImage(response.data);
      })
      .catch(error => console.log(error.message));
  });
  return (
    <div>
      <InfoPresenter title="Ver documento adjunto">
        {render(image)}
      </InfoPresenter>
    </div>
  );
}

const render = image => {
  if (image) {
    return <img src={`data:image/jpeg,${image}`} />;
  }

  return <Typography>No se ha subido ningún documento</Typography>;
};
