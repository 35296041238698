import React from "react";
import InfoPresenter from "../ui/dialogs/InfoPresenter";
import Typography from "@material-ui/core/Typography";
import IsolateTextField from "../../views/operations/viewOperation/IsolateTextField";
import FieldRevisionManager from "../../views/operations/viewOperation/FieldRevisionManager";

const DocumentData = ({
  document,
  changeFieldData,
  changeFieldStatus,
  addObservation
}) => {
  const onUpdateField = async field => {
    changeFieldData({ document, field });
  };

  const onChangeFieldState = (fieldToUpdate, newStatus) => {
    changeFieldStatus({
      document,
      fieldToUpdate,
      newStatus
    });
  };
  return (
    <div>
      <InfoPresenter title="¿Que es este documento?">
        {document.meta.info}
      </InfoPresenter>
      <Typography variant="body2" color="secondary">
        * Para que el documento sea válido se deben completar y aprobar los
        campos con la información del documento.
      </Typography>
      {console.log("documento", document)}
      {document.fields.map(field => {
        return (
          <div>
            <IsolateTextField
              field={field}
              isPending={field.status === 0}
              status={field.status}
              handleClick={onUpdateField}
              handleChange={onChangeFieldState}
            />
            <FieldRevisionManager
              observations={field.observations}
              addObservation={newObservation =>
                addObservation({ document, field, newObservation })
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default DocumentData;
