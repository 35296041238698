import React from "react";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";

const RejectButton = ({ disabled = false, title = "Aprobar", onClick }) => {
  return (
    <Button disabled={disabled} title={title} onClick={onClick}>
      <CloseIcon color={"error"} />
    </Button>
  );
};

export default RejectButton;
